import React from "react"
import ImageCover from "../components/ImageCover"
import Layout from "../components/layout"
import RegionGrid from "../components/RegionGrid/RegionGrid"
import { graphql } from "gatsby"
import styled from "styled-components"
import Seo from "../components/seo"

const CompoSec = styled.section`
  .featureHeading {
    height: 292px;
    position: relative;
    max-width: 98%;
    margin: 0 auto;
    filter: drop-shadow(0 0 10px #0003);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 40px;
  }
  .featureHeading img {
    overflow: hidden;
    height: 292px;
    object-position: right bottom !important;
  }
  .headingBLS {
    color: white;
    z-index: 3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
  }
  .featuredLink a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export default function FrenchWines({ data }) {
  //console.log(data)
  const wines = data.allStrapiRegions.nodes
    .filter(e => e.region !== "VSIG")
    .map(e => ({
      title: e.region,
      slug: e.slug,
      image: e.Image?.localFile.childImageSharp?.gatsbyImageData,
      // ? e.Image.childImageSharp.fixed
      // : null,
    }))

  wines.sort(function (a, b) {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  })
  // wines.push({
  //   title: "French Wines from Bruno Lafon",
  //   slug: data.bls.nodes[0].Slug,
  //   image: data.bls.nodes[0].MainImage.childImageSharp.fixed,
  // })

  const image = data.strapiRegions

  return (
    <Layout relative={false}>
      <Seo title="French Wine Selection" />
      <CompoSec>
        <ImageCover
          image={image.Image.localFile.childImageSharp.gatsbyImageData}
          heading={"France"}
          section={"#wines"}
          third
          truedat={true}
        />

        <RegionGrid bigData={wines} />
      </CompoSec>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiRegions(region: { eq: "France" }, country: { eq: "France" }) {
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
          }
        }
      }
    }
    allStrapiRegions(
      filter: { country: { eq: "France" }, region: { ne: "France" } }
    ) {
      nodes {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 60, placeholder: BLURRED)
            }
          }
        }
        country
        region
        slug
      }
    }
  }
`
